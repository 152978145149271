.cameraMask {
    background-image:  url("/images/silhouettes/car_front.png");
    background-repeat: no-repeat;
    /* height: 100vh;
    width: 60vw;  */
    background-size: 100%;
    position: absolute;
    opacity: 0.4;
    z-index: 1;
    margin: 0;
    padding:0;    
}

.cameraScreen {
    margin: 0;
    padding:0;  
    border: 1px solid red; 
    /* height: 80vh;
    width: 100%; */
    /*width: 60vw;   */
}