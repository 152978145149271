.gridButtons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(55px, 60px));
    justify-content: center;
    gap: 5px;
}

.gridCard {
    display: grid;
    background-color: transparent;
    grid-template-rows: 0.6fr auto 1fr;
    border: none;
    vertical-align: baseline;
}

.PhotoBox {
    padding: 5px;
    border: 1px solid #1C467A;
    border-radius: 4px;
    margin: 10px;
}

.boxDescription {
    margin: 10px;
}

.dzu-inputLabel {
    font-size: 1em;
    text-align: center;    
}

.dzu-previewContainer {
    background-color: none;
    color: transparent;
    border: none;
   
    /* silver;
    color: red;
    font-weight: bold;
    */
}
.dzu-previewContainer span:nth-child(2) {
    color: red;
}

.dzu-previewContainer span:nth-child(1) {
    font-size: .1em;
    display: hidden;
}

.dzu-previewButton {
    background-size: 40px 40px;
    width: 50px;
    height: 50px;
}
.dzu-previewFileNameError {
    width: 10%;
}

.dzu-dropzone {
    margin: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 196px;
    max-height: 196px;
    border: none;
    max-width: 240px;    
}

@media screen and (max-width: 30em) {
    .dzu-dropzone {
        max-width: 180px;
    }
}

.dzu-previewFileName {
    border-radius: 6px;
    padding: 2px;
    background-color: rgba(170, 170, 170, 0.8);
}

.dzu-submitButtonContainer{
    visibility: hidden;
}

.dzu-dropzone:hover {
    background-color: rgba(170, 170, 170, 0.8)!important;
}

.previewStatusContainer {
    flex-wrap: wrap;
}

.cardImage {        
    /*width: auto;    
    max-height: 150px;*/
    /*justify-content: center;
    align-items: center;*/
    margin-top: auto;
    margin-bottom: auto;    
    object-fit: cover;
    width: 100%;
    max-height: 200px;
}

.pictureCardBody {
    background-color: #d0d0d0;
    border: 1px solid #1C467A;
    padding: 0px;
    min-height: 200px;
}

.pictureCardHeader {
    padding: 0px;
    min-height: 3em;
}

.pictureCardFooter {
    height: auto;
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    border: 1px solid #1C467A;
    background-color: #1C467A;
    min-height: 110px;
}


.cardImage:hover {        
    opacity: 0.7;
}



.uniqueHeight {
    min-height: 300px;
    _height: 300px;
}

.photoCardTitle {    
    text-align: left;
}

.card-header {
    color: #1C467A;
    border-radius: 0px !important;
    background-color: transparent;    
    border: none !important;
}

.card{
    border-radius: 0.50rem;
}    

.iconStyle {
    color: white;
}

.noPhotoInfo {
    font-weight: bold;
}


.deletePhotoBoxPic:hover{
    opacity: 0.7;
}

.fileInformation {
    margin: 10px;
    background-color: rgba(170, 170, 170, 0.8);
    padding: 5px;
    border-radius: 4px;
}

.fileInformationName {
    
    align-items: center;
    text-align: center;
    font-size: 1em;
    font-weight: bold;    
}

.checker {    
    color: green;
    font-size: 1.5em;
}


.photoNotComplete {
    border: 3px solid red;
}

.photoComplete {
    border: 2px solid #50a0a0;
}

.photoInitial {
    border: 1px solid #1C467A;
}

.checkCardBoxGrid {    
    grid-template-columns: repeat(auto-fill, minmax(140px, 2fr));   
}

.checkCardBoxFooter {
    font-size: 1em;
}

.checkCardBoxTitle {
    font-size: 1em;
}

.checkCardBoxHeight {
   /*  min-height: 100px !important; */
}