.mainLayout {
    margin-top: 16px;    
}

.layoutFooter {
    height: 40px;
    margin-top: 20px;
    background-color: #1C467A !important;
}
.layoutHeader {
    height: 20px;
    margin-bottom: 20px;
    /*background-color: #1C467A;*/
}

.headerLogoBox {
    margin-top: 15px;
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
}

.headerLogoText {
    color: #c0c0c0;
    text-align: center;
    font-size: 1.1em;
    font-weight: 600;
}

.vuName {
    text-align: center;
    font-weight: bold;
    font-size: 1.6em;
    color: #1C467A; 
}

.appFooterHead {
    color: #1C467A;
    font-size: 1.2em;
}

.appFooterFoot {
    font-weight: 600;
    margin-top:  10px;
    color: #1C467A;
    font-size: 1.2em;
}

.noteLight {
    font-weight: 400;
}

.appFooter { 
    margin-top:  20px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-content: center;
}
.appFooterLogoBox {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 80%;
}

.App-logo {
    display: block;
    margin: auto;
    max-width: 50%;
}

.appFooterLogo {
    width: 90%;
}

.endLine {
    border: 0px;
    height: 5px;
    background-color: #e0e0e0;
    margin-bottom: 0px !important;
    margin-block-end: 0;
}

.endLineControls {
    display: grid;
    grid-template-columns: 1fr auto 3fr auto 1fr;
    margin-bottom: 30px;
}

.mainInfoButton {
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 15px;
    padding-right: 15px;
    color: #1C467A;
    background-color: #e0e0e0;
    border: none;
}

.mainInfoButton:hover {    
    background-color: #d0d0d0;
}

.tapiLink {
    color: #1C467A;
}

.marginTop50 {
    margin-top: 50px;
}

.errorMessageBox {
    padding: 10px;
    font-weight: bold;
    text-align: center;
}

.partFotoHeader {
    background-color: #1C467A;
    color: white;
    font-weight: bold;
}

.partFotoEntry {
    text-align: center;
    font-weight: bold;
}

.margin-right-5 {
    margin-right: 5px;
}

.clickableCarDescription {
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
}