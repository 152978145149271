.modalCard {
    background: rgba(255,255,255);    
    border: 5px solid #1C467A;
    border-radius: 4px;
    padding: 15px;
}

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0,0,0,0.75);
}

.modalMessage {
    position: fixed;
    top: 30ch;
    left: 25%;
    width: 50%;
    z-index: 100;
    overflow: hidden;
}

.modalHader {
    background: #4f005f;
    padding: 1rem;
}

.modalContent {
    padding: 1rem;
}

.modalActions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 768px) {
    .modal {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}