.partNameBox {
    /* transform: translate3d(5%, 0, 0); */
    position:relative;
    left: -50%;

    width: 220px;
    padding: 5px;
    border: 1px solid black;
    background-color: #e0e0e0aa;   
    border-radius: 5px; 
}
.partNameBoxContent {
    /* padding-top: 10px; */
    text-align: left;
    color:#808080;
    font-weight: bold;
  }

  
  /* .partNameBoxContent::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 250px;
    transform: translate3d(-40px, 0, 0);
    height: 1px;
    background: white;
  } */