.CarpicHeader {
    display: grid;
    gap: 10px;
    grid-template-rows: 1fr auto;
    margin-top: 30px;
    font-size: 1.3em;
    font-weight: bold;
}

.formatText {
    border: 2px solid  #1C467A;
    border-radius: 6px;
    padding: 5px;
    background-color: #cacaca;
}


.headerCardHeader {
    border-radius: 0px !important;
    background-color: #1C467A;
    color: white;
    padding-left: 15px;
}

.headerCardBody {
    background-color: #eaeaea;
    padding-left: 15px;    
}

.headerHeadlineText {
    padding-left: 15px;
    font-weight: bold;
    font-size: 1.1em;
}

.headerNormalText {
    padding-left: 15px;
    font-weight: normal;
    font-size: 1em;
}

.headerCardFooter {
    height: 10px;
    border-radius: 0px;
    background-color: #1C467A;
}

.boldFont {
    font-weight: 600;
}

.linksToGridBox {
    text-decoration: none;
    color: black;
}

.badgeRight {
    float: right;
    color: #1C467A;
    font-size: .8em;
    background-color: #fcf0a9;
}

@media screen and (max-width: 30em) {
    .badgeRight {
        font-size: .5em;
    }
}

