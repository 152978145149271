.photoListBoxHeader {
   height: 30px;
   background-color: #a0a0a0;
   border-radius: 0px;
}

.photoListHeaderTitle {
   margin-top: 20px;
}

.photoGrid {
   display: grid;
   background-color: #e0e0e0;   
   /*grid-template-columns: repeat(auto-fit, minmax(200px, 350px));         */
   grid-template-columns: repeat(auto-fill, minmax(230px, 3fr));   
   grid-auto-flow: dense;
   gap:10px;  
   padding: 20px;
}

@media screen and (max-width: 30em) {
   .photoGrid {
      grid-template-columns: repeat(auto-fill, minmax(180px, 3fr));
   }
}

.krugPhotoButtonColor {
   background-color: #1C467A;
}