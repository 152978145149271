body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.iosMessage {
  width: 50%;
  color: white !important;
  background-color: #1C467A !important;
}

.iosMessageBox {
  display: grid;
  grid-template-rows: 2fr 1fr 2fr;
}

.AlertBoxLogo {
  width: 50%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
}
.gridButtons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(55px, 60px));
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
}

.gridCard {
    display: grid;
    background-color: transparent;
    grid-template-rows: 0.6fr auto 1fr;
    border: none;
    vertical-align: baseline;
}

.PhotoBox {
    padding: 5px;
    border: 1px solid #1C467A;
    border-radius: 4px;
    margin: 10px;
}

.boxDescription {
    margin: 10px;
}

.dzu-inputLabel {
    font-size: 1em;
    text-align: center;    
}

.dzu-previewContainer {
    background-color: none;
    color: transparent;
    border: none;
   
    /* silver;
    color: red;
    font-weight: bold;
    */
}
.dzu-previewContainer span:nth-child(2) {
    color: red;
}

.dzu-previewContainer span:nth-child(1) {
    font-size: .1em;
    display: hidden;
}

.dzu-previewButton {
    background-size: 40px 40px;
    width: 50px;
    height: 50px;
}
.dzu-previewFileNameError {
    width: 10%;
}

.dzu-dropzone {
    margin: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 196px;
    max-height: 196px;
    border: none;
    max-width: 240px;    
}

@media screen and (max-width: 30em) {
    .dzu-dropzone {
        max-width: 180px;
    }
}

.dzu-previewFileName {
    border-radius: 6px;
    padding: 2px;
    background-color: rgba(170, 170, 170, 0.8);
}

.dzu-submitButtonContainer{
    visibility: hidden;
}

.dzu-dropzone:hover {
    background-color: rgba(170, 170, 170, 0.8)!important;
}

.previewStatusContainer {
    flex-wrap: wrap;
}

.cardImage {        
    /*width: auto;    
    max-height: 150px;*/
    /*justify-content: center;
    align-items: center;*/
    margin-top: auto;
    margin-bottom: auto;    
    object-fit: cover;
    width: 100%;
    max-height: 200px;
}

.pictureCardBody {
    background-color: #d0d0d0;
    border: 1px solid #1C467A;
    padding: 0px;
    min-height: 200px;
}

.pictureCardHeader {
    padding: 0px;
    min-height: 3em;
}

.pictureCardFooter {
    height: auto;
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    border: 1px solid #1C467A;
    background-color: #1C467A;
    min-height: 110px;
}


.cardImage:hover {        
    opacity: 0.7;
}



.uniqueHeight {
    min-height: 300px;
    _height: 300px;
}

.photoCardTitle {    
    text-align: left;
}

.card-header {
    color: #1C467A;
    border-radius: 0px !important;
    background-color: transparent;    
    border: none !important;
}

.card{
    border-radius: 0.50rem;
}    

.iconStyle {
    color: white;
}

.noPhotoInfo {
    font-weight: bold;
}


.deletePhotoBoxPic:hover{
    opacity: 0.7;
}

.fileInformation {
    margin: 10px;
    background-color: rgba(170, 170, 170, 0.8);
    padding: 5px;
    border-radius: 4px;
}

.fileInformationName {
    
    align-items: center;
    text-align: center;
    font-size: 1em;
    font-weight: bold;    
}

.checker {    
    color: green;
    font-size: 1.5em;
}


.photoNotComplete {
    border: 3px solid red;
}

.photoComplete {
    border: 2px solid #50a0a0;
}

.photoInitial {
    border: 1px solid #1C467A;
}

.checkCardBoxGrid {    
    grid-template-columns: repeat(auto-fill, minmax(140px, 2fr));   
}

.checkCardBoxFooter {
    font-size: 1em;
}

.checkCardBoxTitle {
    font-size: 1em;
}

.checkCardBoxHeight {
   /*  min-height: 100px !important; */
}
.photoListBoxHeader {
   height: 30px;
   background-color: #a0a0a0;
   border-radius: 0px;
}

.photoListHeaderTitle {
   margin-top: 20px;
}

.photoGrid {
   display: grid;
   background-color: #e0e0e0;   
   /*grid-template-columns: repeat(auto-fit, minmax(200px, 350px));         */
   grid-template-columns: repeat(auto-fill, minmax(230px, 3fr));   
   grid-auto-flow: dense;
   grid-gap:10px;
   gap:10px;  
   padding: 20px;
}

@media screen and (max-width: 30em) {
   .photoGrid {
      grid-template-columns: repeat(auto-fill, minmax(180px, 3fr));
   }
}

.krugPhotoButtonColor {
   background-color: #1C467A;
}
.uploadHeader {
    background-color: #1C467A;
    color: white;
    border-radius: 4px;
    padding: 10px;
}

.marginTop30 {
    margin-top: 30px;
}

.krugButtonColor {
    background-color: #1C467A;
    color: white;
    border: none;
}
.modalCard {
    background: rgba(255,255,255);    
    border: 5px solid #1C467A;
    border-radius: 4px;
    padding: 15px;
}

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0,0,0,0.75);
}

.modalMessage {
    position: fixed;
    top: 30ch;
    left: 25%;
    width: 50%;
    z-index: 100;
    overflow: hidden;
}

.modalHader {
    background: #4f005f;
    padding: 1rem;
}

.modalContent {
    padding: 1rem;
}

.modalActions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 768px) {
    .modal {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}
.Controls {
    margin: 10px;
}

.footerGrid {
    margin-bottom:20px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
}

.chargeType {
    width: auto;
}

.contolButtons {
    width: 100px;
}

.buttonCancel {
    background-color: silver;
    border: none;
}

.buttonCancel:hover {
    background-color: #a0a0a0;    
}

.linkToDatenschutz {
    color: #1C467A;
}

.marginBottom30 {
    margin-bottom: 30px;
}

.marginTop10 {
    margin-top: 10px;
}

.marginBottom15 {
    margin-bottom: 15px;
}

.centerBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.blueLineThin {
    height: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #1C467A !important;
}

.dsgvoCheckbox {
    font-size: 1.1em;
    font-weight: bold;
}

.controlBoxHeader {
    height: 30px;
    background-color: #a0a0a0;
    border-radius: 0px;
    margin-bottom: 10px;
 }

 .emailError {
    background-color: #aa3030;
 }

 .inputText {
     width: 80%;
 }

 .modalMessage {
    margin: 5% auto;
     top: 50px;
     left: 0;
     right: 0;
     width: 60%;
    }
    
    @media screen and (max-width: 30em) {
        .modalMessage {        
            width: 90%;
            /*top: 2px;
            left: 0px; */
    }
}

 .modalCard {
    border: 2px solid #1C467A;
    padding:5px;
 }
.partNameBox {
    /* transform: translate3d(5%, 0, 0); */
    position:relative;
    left: -50%;

    width: 220px;
    padding: 5px;
    border: 1px solid black;
    background-color: #e0e0e0aa;   
    border-radius: 5px; 
}
.partNameBoxContent {
    /* padding-top: 10px; */
    text-align: left;
    color:#808080;
    font-weight: bold;
  }

  
  /* .partNameBoxContent::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 250px;
    transform: translate3d(-40px, 0, 0);
    height: 1px;
    background: white;
  } */
.cameraMask {
    background-image:  url("/images/silhouettes/car_front.png");
    background-repeat: no-repeat;
    /* height: 100vh;
    width: 60vw;  */
    background-size: 100%;
    position: absolute;
    opacity: 0.4;
    z-index: 1;
    margin: 0;
    padding:0;    
}

.cameraScreen {
    margin: 0;
    padding:0;  
    border: 1px solid red; 
    /* height: 80vh;
    width: 100%; */
    /*width: 60vw;   */
}
.threebox {
    /* border: 1px solid red;     */
    height: 80vh !important;
    /* width: 100% !important;  */
}
.ClickableCarBox {
    /* width: 100%;
    height: 100%; */
    background-color: #e0e0e0;   
    padding: 10px;
    display: grid;
    /* grid-template-columns: 1fr auto 1fr; */
    justify-content: center;
}
.CarpicHeader {
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-template-rows: 1fr auto;
    margin-top: 30px;
    font-size: 1.3em;
    font-weight: bold;
}

.formatText {
    border: 2px solid  #1C467A;
    border-radius: 6px;
    padding: 5px;
    background-color: #cacaca;
}


.headerCardHeader {
    border-radius: 0px !important;
    background-color: #1C467A;
    color: white;
    padding-left: 15px;
}

.headerCardBody {
    background-color: #eaeaea;
    padding-left: 15px;    
}

.headerHeadlineText {
    padding-left: 15px;
    font-weight: bold;
    font-size: 1.1em;
}

.headerNormalText {
    padding-left: 15px;
    font-weight: normal;
    font-size: 1em;
}

.headerCardFooter {
    height: 10px;
    border-radius: 0px;
    background-color: #1C467A;
}

.boldFont {
    font-weight: 600;
}

.linksToGridBox {
    text-decoration: none;
    color: black;
}

.badgeRight {
    float: right;
    color: #1C467A;
    font-size: .8em;
    background-color: #fcf0a9;
}

@media screen and (max-width: 30em) {
    .badgeRight {
        font-size: .5em;
    }
}


.mainLayout {
    margin-top: 16px;    
}

.layoutFooter {
    height: 40px;
    margin-top: 20px;
    background-color: #1C467A !important;
}
.layoutHeader {
    height: 20px;
    margin-bottom: 20px;
    /*background-color: #1C467A;*/
}

.headerLogoBox {
    margin-top: 15px;
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
}

.headerLogoText {
    color: #c0c0c0;
    text-align: center;
    font-size: 1.1em;
    font-weight: 600;
}

.vuName {
    text-align: center;
    font-weight: bold;
    font-size: 1.6em;
    color: #1C467A; 
}

.appFooterHead {
    color: #1C467A;
    font-size: 1.2em;
}

.appFooterFoot {
    font-weight: 600;
    margin-top:  10px;
    color: #1C467A;
    font-size: 1.2em;
}

.noteLight {
    font-weight: 400;
}

.appFooter { 
    margin-top:  20px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-content: center;
}
.appFooterLogoBox {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 80%;
}

.App-logo {
    display: block;
    margin: auto;
    max-width: 50%;
}

.appFooterLogo {
    width: 90%;
}

.endLine {
    border: 0px;
    height: 5px;
    background-color: #e0e0e0;
    margin-bottom: 0px !important;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.endLineControls {
    display: grid;
    grid-template-columns: 1fr auto 3fr auto 1fr;
    margin-bottom: 30px;
}

.mainInfoButton {
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 15px;
    padding-right: 15px;
    color: #1C467A;
    background-color: #e0e0e0;
    border: none;
}

.mainInfoButton:hover {    
    background-color: #d0d0d0;
}

.tapiLink {
    color: #1C467A;
}

.marginTop50 {
    margin-top: 50px;
}

.errorMessageBox {
    padding: 10px;
    font-weight: bold;
    text-align: center;
}

.partFotoHeader {
    background-color: #1C467A;
    color: white;
    font-weight: bold;
}

.partFotoEntry {
    text-align: center;
    font-weight: bold;
}

.margin-right-5 {
    margin-right: 5px;
}

.clickableCarDescription {
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
}
.previewBox {  
    margin-left: auto;  
    margin-right: auto;
    display: grid;    
    width: 60%;
    grid-gap: 10px;
    gap: 10px;
    grid-template-rows: 2fr auto auto 2fr;   
}

.previewHeader {    
    color:white;
    text-align: center;
    font-weight: bold;
}

.previewImageBox {
    display: grid;
}

.previewImage {    
    /*border-radius: 6px;*/
    justify-content: center;   
    object-fit: cover;     
    width: 100%;
    height: auto;
}
