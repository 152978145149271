.Controls {
    margin: 10px;
}

.footerGrid {
    margin-bottom:20px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
}

.chargeType {
    width: auto;
}

.contolButtons {
    width: 100px;
}

.buttonCancel {
    background-color: silver;
    border: none;
}

.buttonCancel:hover {
    background-color: #a0a0a0;    
}

.linkToDatenschutz {
    color: #1C467A;
}

.marginBottom30 {
    margin-bottom: 30px;
}

.marginTop10 {
    margin-top: 10px;
}

.marginBottom15 {
    margin-bottom: 15px;
}

.centerBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.blueLineThin {
    height: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #1C467A !important;
}

.dsgvoCheckbox {
    font-size: 1.1em;
    font-weight: bold;
}

.controlBoxHeader {
    height: 30px;
    background-color: #a0a0a0;
    border-radius: 0px;
    margin-bottom: 10px;
 }

 .emailError {
    background-color: #aa3030;
 }

 .inputText {
     width: 80%;
 }

 .modalMessage {
    margin: 5% auto;
     top: 50px;
     left: 0;
     right: 0;
     width: 60%;
    }
    
    @media screen and (max-width: 30em) {
        .modalMessage {        
            width: 90%;
            /*top: 2px;
            left: 0px; */
    }
}

 .modalCard {
    border: 2px solid #1C467A;
    padding:5px;
 }