.uploadHeader {
    background-color: #1C467A;
    color: white;
    border-radius: 4px;
    padding: 10px;
}

.marginTop30 {
    margin-top: 30px;
}

.krugButtonColor {
    background-color: #1C467A;
    color: white;
    border: none;
}