.previewBox {  
    margin-left: auto;  
    margin-right: auto;
    display: grid;    
    width: 60%;
    gap: 10px;
    grid-template-rows: 2fr auto auto 2fr;   
}

.previewHeader {    
    color:white;
    text-align: center;
    font-weight: bold;
}

.previewImageBox {
    display: grid;
}

.previewImage {    
    /*border-radius: 6px;*/
    justify-content: center;   
    object-fit: cover;     
    width: 100%;
    height: auto;
}